import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../../Components/Services/Services";

const ScanToBimContent = {
  desc: "We are experts in converting point cloud data into detailed BIM models that can be used for reconstruction, restoration, retrofit and refurbishment of existing buildings and infrastructure. Our Point Cloud to BIM solutions are also be used to create as-built models or they can be utilized for operation and management of a facility. We specialize in accurately identifying any inter or interdisciplinary hard, soft or workflow clash in the model. Our experience and expertise also means that we can provide design alternatives and clash resolution solutions that streamlines onsite construction.",
  services: [
    "Scan to BIM for Historic Monuments",
    "Scan to BIM for Infrastructure Projects",
    "Point Cloud to BIM Services for Residential and Comercial Projects",
  ],
  images: [
    "/Images/Services/Scan/1.jpeg",
    "/Images/Services/Scan/2.jpeg",
    "/Images/Services/Scan/1.jpeg",
  ],
};

export default function ScanToBIM() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={12} md={12} lg={12} className='m-0 services-title-container'>
          <span className='title-services align-self-center '>
            <span style={{ color: "#e60405" }}> Scan</span> to BIM Services
          </span>
        </Col>
        <Services content={ScanToBimContent} />
      </Row>
    </Container>
  );
}
