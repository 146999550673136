import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../../Components/Services/Services";

const BIMCoordinationContent = {
  desc: "We identify design errors, issues and inter or intra disciplinary geometrical conflicts in the BIM model to create a coordinated BIM model. Our ability to accurate detect clashes in the model helps our clients reduce onsite redundancy and saves on cost, time and resources.",
  services: [
    "Integrated Model and its Coordination Services",
    "Clash Detection with all respects",
    "Timeline Simulation of Model ",
  ],
  images: [
    "/Images/Services/Coordination/1.jpg",
    "/Images/Services/Coordination/2.jpg",
    "/Images/Services/Coordination/3.jpg",
  ],
};

export default function BIMCoordination() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={12} md={12} lg={12} className='m-0 services-title-container'>
          <span className='title-services align-self-center '>
            BIM <span style={{ color: "#e60405" }}> Coordination </span>
            Services
          </span>
        </Col>
        <Services content={BIMCoordinationContent} />
      </Row>
    </Container>
  );
}
