import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ItemContent = [
  {
    title: "About mprocs",
    src: "/SVG/company.svg",
    url: "/",
    desc: "Welcome to our team! We are a group of highly qualified professionals with expertise in engineering, planning, scheduling",
  },
  {
    title: "Civil Engineering",
    src: "/SVG/engineering.svg",
    url: "/services/engineering/civil",
    desc: "Our team specializes in providing comprehensive civil engineering services for industrial projects, including foundations ",
  },
 
  {
    title: "Scheduling",
    src: "/SVG/time.svg",
    url: "/services/time-management",
    desc: "Our team specializes in providing advanced  scheduling services to help clients optimize the progress of their projects and ",
  },
  {
    title: "Estimation and Costing",
    src: "/SVG/cost-1.svg",
    url: "/services/costing",
    desc: "Our team specializes in providing comprehensive budgeting and financial planning services for projects of all sizes and industries",
  },
  {
    title: "Assets and facility Management",
    src: "/SVG/management.svg",
    url: "/services/assets-facility-management",
    desc: "Our team specializes in creating customized tools for asset and facility management using the powerful capabilities of Power BI and Power Apps. These tools ",
  },
  {
    title: "Primavera Training",
    src: "/SVG/model.svg",
    url: "/services/ Primavera Training",
    desc: "Are you an engineering student or a recent graduate looking to enhance your project management skills? Or perhaps you are a construction worker, supervisor, or field worker aiming to advance your career? Our Primavera training is designed just for you!",
  },
];

const Item = ({ title, src, desc, url }) => {
  return (
    <Col
      sm={12}
      md={6}
      lg={4}
      className='mt-5 d-flex flex-column align-items-center justify-content-center text-center'
      style={{
        cursor: "pointer",
      }}
    >
      <img
        src={src}
        alt={title}
        style={{
          width: "80px",
          height: "80px",
        }}
      />
      <span className='my-3 fs-5' style={{ color: "#e60405" }}>
        {title}
      </span>
      <span className='fs-7 w-75'>
        {desc + " "}
        <Link to={url}>Read More ...</Link>
      </span>
    </Col>
  );
};

export default function MiddleSection() {
  return (
    <Container
      fluid
      className='d-flex align-items-center justify-content-center flex-column  m-auto py-5'
    >
      <span className='fs-1'>
        Welcome To
        <span style={{ color: "#e60405" }}> mprocs</span>
      </span>
      <Row>
        {ItemContent.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </Row>
    </Container>
  );
}
