import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Services from "../../../Components/Services/Services";

const ArchitecturalBIMContent = {
  desc: "We are specialize in creating accurate and detailed Architectural BIM Models according to the client’s specifications and the required LOD. Our comprehensive Architectural BIM Services provides a multitude of advantages to architects, construction companies, builders, general contractors etc. throughout the design and construction process.",
  services: [
    "CAD to BIM Conversion Services",
    "Exterior Architectural BIM Modeling Services",
    "Interior Architectural BIM Modeling Services",
    "Floor Plans Services",
  ],
  images: [
    "/Images/Services/Architectural/1.jpg",
    "/Images/Services/Architectural/2.jpg",
    "/Images/Services/Architectural/3.jpg",
  ],
};

export default function ArchitecturalBIM() {
  return (
    <Container fluid className='m-0 p-0 services-container'>
      <Row className='m-0 p-0 mb-5'>
        <Col sm={8} md={8} lg={12} className='m-0 services-title-container'>
          <span className='align-self-center title-services'>
            <span style={{ color: "#e60405" }}> Architectural</span> BIM
            Services
          </span>
        </Col>
        <Services content={ArchitecturalBIMContent} />
      </Row>
    </Container>
  );
}
