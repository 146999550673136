export const data = [

  {
    id: 1,
    name: "Farhan Muhammad",
    position: "Founder and CEO ",
    image: "/Images/Team/Farhan-Muhammad.png",
  },

  {
    id: 2,
    name: "Ghulam Hasnain",
    position: "Co-Founder CTO",
    image: "/Images/Team/Hasnain.png",
  },


  {
    id: 3,
    name: " Hans Raj ",
    position: "Senior Project Controls Manager",
    image: "/Images/Team/Hans Raj.png",
  },

  {
    id: 4,
    name: "Saad Abouchachine",
    position: "Project Control Specialist",
    image: "/Images/Team/Saad Abouchachine.jpeg",
  },

    {

    id: 5,
    name: "Ahsan Khan",
    position: "Planner/Scheduler",
    image: "/Images/Team/Ahsan-Khan.png",
  },


  {
    id: 6,
    name: "Brett McWilliams",
    position: "Planner/Scheduler",
    image: "/Images/Team/Brett-McWilliams.png",
  },

{
    id: 7,
  name: "Vraj Shah" ,
  position: "Industrial Engineer and PowerBI/Power App Developer",
  image: "/Images/Team/Vraj-Shah.png",

},

  {
    id: 8,
    name: "Aeid Elboyuk",
    position: "Civil and Structural Engineer",
    image: "/Images/Team/Aeid-Elboyuk.png",
  },
  {
    id: 9,
    name: "Ron Gallardo",
    position: " Structure Engineer (B.Eng.)",
    image: "/Images/Team/Ron Gallardo.png",
  },

];
