import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { ImFacebook, ImTwitter } from "react-icons/im";
import { Link } from "react-router-dom";

function CustomNavbar() {
  return (
    <Navbar bg="light" expand="lg" className="py-3">
      <Container>
        <Navbar.Brand>
          <Link to="/" className="link">
            <img
              style={{ width: "150px", objectFit: "contain" }}
              src="/Images/mprocs-1.png"
              alt="mprocs"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto d-flex align-items-center me-5">
            <Nav.Link>
              <Link to="/" className="link navbar-link ">
                Home
              </Link>
            </Nav.Link>

            <NavDropdownMenu title="Services" id="collasible-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/services/engineering/civil" className="link ">
                  Civil Engineering
                </Link>
              </NavDropdown.Item>
              {/* <DropdownSubmenu title='Civil Engineering'>
                <NavDropdown.Item>
                  <Link to='/services/engineering/civil' className='link'>
                    Civil Engineering
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to='/services/engineering/mechanical' className='link'>
                    Mechanical Engineering
                  </Link>
                </NavDropdown.Item>
              </DropdownSubmenu> */}
              <NavDropdown.Item>
                <Link to="/services/time-management" className="link ">
                  Scheduling Services
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/services/costing" className="link">
                  Costing and Estimation
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  to="/services/assets-facility-management"
                  className="link"
                >
                   Assets and facility Management
                </Link>
              </NavDropdown.Item>
            </NavDropdownMenu>

            <NavDropdownMenu title="Training" id="collasible-nav-dropdown">
              <NavDropdown.Item>
                <Link to="/training/primavera" className="link">
                  Primavera Training
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/training/synchro" className="link">
                  Synchro Training
                </Link>
              </NavDropdown.Item>
            </NavDropdownMenu>
            {/* <NavDropdown title='About' id='basic-nav-dropdown'>
              <NavDropdown.Item>
                <Link to='/about' className='link'>
                  About Us
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to='/why' className='link'>
                  {" "}
                  Why Us
                </Link>
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link>
              {" "}
              <Link to="/about" className="link navbar-link">
                About Us
              </Link>
            </Nav.Link>
            <Nav.Link>
              {" "}
              <Link to="/team" className="link navbar-link">
                Our Team
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/contact" className="link navbar-link">
                Contact
              </Link>
            </Nav.Link>

            {/* <Nav.Link href='https://www.facebook.com/profile.php?id=100065000291064'>
              <ImFacebook className='facebook' />
            </Nav.Link>
            <Nav.Link href='https://twitter.com/P6Solutions'>
              <ImTwitter className='twitter' />
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
