import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";

export default function Footer() {
  return (
    <Container fluid className='m-0 p-0 footer-container'>
      <Row className='footer-details'>
        <Col md={12} lg={4} className='footer-col'>
          <h4>mprocs</h4>
          <ul>
            <span>
              We are a team of highly educated professional individuals with
              decades of experience in engineering, BIM, planning,
              scheduling, project controls, and operation management in
              industrial, commercial and infrastructure projects. Our services
              are mainly in Oil Sands, Oil &amp; Gas, Refineries, SAGD, Mining,
              Highways, Bridges, High-rise Commercial and Residential Buildings.
            </span>
          </ul>
        </Col>
        <Col md={12} lg={4} className='footer-col'>
          <h4>mprocs Scheduling Services</h4>
          <ul>
            <li>
              <a href='#'>Project Scheduler - Full Time</a>
            </li>
            <li>
              <a href='#'>Project Scheduler - Part Time</a>
            </li>
            <li>
              <a href='#'>Shutdown/ Turnaround Planner Scheduler</a>
            </li>
            <li>
              <a href='#'>Forensic Analysis of Project Schedule</a>
            </li>
            <li>
              <a href='#'>Bid Schedule</a>
            </li>
            <li>
              <a href='#'>Fabrication Schedule</a>
            </li>
          </ul>
        </Col>
        <Col md={12} lg={4} className='footer-col'>
          <h4>mprocs Project Controls Services</h4>
          <ul>
            <li>
              <a href='#'>Project Risk Analysis</a>
            </li>
            <li>
              <a href='#'>Create Dashboard using Power BI</a>
            </li>
            <li>
              <a href='#'>Create Power apps for project controls</a>
            </li>
            <li>
              <a href='#'>Create progress collection system using Power apps</a>
            </li>
            <li>
              <a href='#'>Create cost control system</a>
            </li>
            <li>
              <a href='#'>Create change managment system</a>
            </li>
          </ul>
        </Col>
        {/* <Col md={6} lg={3} className='footer-col'>
          <h4>More Services</h4>
          <ul>
            <li>
              <a href='#'>Civil and Structure engineering services</a>
            </li>
            <li>
              <a href='#'>Technical Services</a>
            </li>
            <li>
              <a href='#'>Support Services</a>
            </li>
            <li>
              <a href='#'>Training Services</a>
            </li>
            <li>
              <a href='#'>Reporting Services</a>
            </li>
          </ul>
        </Col> */}
        <span className='copyright'>
          Copyright © 2023 mprocs{" "}
          <span style={{ fontSize: ".6rem" }}>
            (MM Project Controls Services Ltd.)
          </span>
        </span>
      </Row>
    </Container>
  );
}
